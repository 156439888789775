import { Box, Button, HStack, Input, Text } from 'native-base'
import React, { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { apiRoutes } from '../../api/apiRoutes'
import fetchApi, { UnauthorizedError } from '../../api/fetchApi'
import {
  PADDING_WITH_BREAKPOINTS,
  SPACE_WITH_BREAKPOINTS,
} from '../../core/Layout'
import wordmark from '../../images/wordmark.svg'
import { AUTH_TOKEN_KEY, AuthResponse } from './types'

function ShipperLogin() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string>()
  const passwordRef = useRef<HTMLInputElement>()

  const postLogin = useCallback(async () => {
    try {
      setError(undefined)
      const response = await fetchApi.post<AuthResponse>(
        apiRoutes.shipperPortal.auth(),
        {
          email_address: email,
          password,
        }
      )
      if (response.token) {
        localStorage.setItem(AUTH_TOKEN_KEY, response.token)
      }
      navigate('/shipper/create')
    } catch (e: unknown) {
      if (e instanceof UnauthorizedError) {
        setError('Incorrect login details, please try again')
      } else {
        setError('Something went wrong.  Please try again.')
      }
    }
  }, [email, password, navigate])

  return (
    <Box width={'100%'}>
      <HStack
        width={'100%'}
        maxWidth={'800px'}
        justifyContent={'center'}
        alignSelf={'center'}
        space={SPACE_WITH_BREAKPOINTS}
        padding={PADDING_WITH_BREAKPOINTS}
      >
        <Box>
          <img src={wordmark} alt={'Relay wordmark'} width={'300px'} />
        </Box>
        <Box flexGrow={1}>
          <Text>Email</Text>
          <Input
            value={email}
            type={'text'}
            variant={'filled'}
            w={'100%'}
            size={'lg'}
            onChangeText={setEmail}
            placeholder={'test@example.com'}
            _hover={{
              borderColor: 'relay.volt',
            }}
            _focus={{
              borderColor: 'relay.volt',
              _hover: { borderColor: 'relay.volt' },
            }}
            mb={6}
            // when hitting enter key
            onSubmitEditing={() => passwordRef.current?.focus()}
          />
          <Text>Password</Text>
          <Input
            value={password}
            ref={passwordRef}
            type={'password'}
            variant={'filled'}
            w={'100%'}
            size={'lg'}
            onChangeText={setPassword}
            placeholder={'Password'}
            _hover={{
              borderColor: 'relay.volt',
            }}
            _focus={{
              borderColor: 'relay.volt',
              _hover: { borderColor: 'relay.volt' },
            }}
            mb={6}
            // when hitting enter key
            onSubmitEditing={postLogin}
          />
          <Button onPress={postLogin} mt={3}>
            Log In
          </Button>
          {error && <Text color={'relay.error'}>{error}</Text>}
        </Box>
      </HStack>
    </Box>
  )
}

export default ShipperLogin
