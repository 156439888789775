import React from 'react'
import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom'

import FeatureFlag from './core/FeatureFlag'
import CollectionPage from './pages/Collection'
import NoMatch from './pages/NoMatch'
import OrderPage from './pages/Order'
import { C2CLocations, ReturnLocations } from './pages/PitstopLocations'
import CreateShipment from './pages/Shipper/CreateShipment'
import ShipperLogin from './pages/Shipper/Login'

function Layout() {
  return (
    <FeatureFlag>
      <Outlet />
    </FeatureFlag>
  )
}

export const routes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        path: '*',
        element: <NoMatch />,
      },
      {
        path: 'orders/:orderId',
        element: <OrderPage />,
        errorElement: <NoMatch error={'Cannot load data for shipment'} />,
      },
      {
        path: 'orders/:orderId/collection',
        element: <CollectionPage />,
        errorElement: <NoMatch error={'Cannot find collection'} />,
      },
      {
        path: 'return-locations',
        element: <ReturnLocations />,
        errorElement: <NoMatch />,
      },
      {
        path: 'c2c-locations',
        element: <C2CLocations />,
        errorElement: <NoMatch />,
      },
      {
        path: 'shipper/login',
        element: <ShipperLogin />,
        errorElement: <NoMatch />,
      },
      {
        path: 'shipper/create',
        element: <CreateShipment />,
        errorElement: <NoMatch />,
      },
    ],
  },
]

const router = createBrowserRouter(routes)

export default router
