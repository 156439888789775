import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import getReturnLocations from '../../api/getReturnLocations'
import { PitstopLocation } from '../types'
import { mockPitstopLocations } from './__mocks__/pitstopLocations'

const useGetReturnLocations = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchQueryParam = searchParams.get('search')
  const useMockData = searchParams.get('useMockData') === 'true'

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [data, setData] = useState<PitstopLocation[]>([])

  const updateSearchQueryParam = (newValue: string) => {
    setSearchParams((searchParams) => {
      searchParams.set('search', newValue)
      return searchParams
    })
  }

  useEffect(() => {
    const fetchReturnLocations = async () => {
      if (!searchQueryParam) {
        return
      }

      try {
        setLoading(true)

        const res = await getReturnLocations<PitstopLocation[]>(
          searchQueryParam
        )
        setData(res)
        setError('')
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setError((e as any)?.message)
      } finally {
        setLoading(false)
      }
    }

    if (useMockData) {
      setLoading(true)
      // mock the api latency
      setTimeout(() => {
        setLoading(false)
        setData(mockPitstopLocations)
      }, 1500)
    } else {
      fetchReturnLocations()
    }
  }, [searchQueryParam, useMockData])

  return {
    loading,
    error,
    data,
    searchQueryParam,
    updateSearchQueryParam,
  }
}

export default useGetReturnLocations
