import { Box, Button, Modal, Text, VStack } from 'native-base'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import courierImg from '../../../../images/security.png'
import { useOrderPageContext } from '../../OrderPageContext'
import { SafePlace as SafePlaceType } from '../../types'
import ModalRelayLogo from '../shared/ModalRelayLogo'
import { useIsTiktokSafePlace } from '../shared/useIsTiktokSafePlace'
import {
  getFallbackSafePlaceList,
  NEIGHBOUR_ID,
  neighbourMaxCharacterLimit,
} from './safePlaceList'
import SafePlaceListRadioGroup from './SafePlaceListRadioGroup'
import SafePlaceMultiCheckbox from './SafePlaceMultiCheckbox'
import useSafePlace from './useSafePlace'

const COURIER_IMAGE_SIZE = 150
const StyledCourierImg = styled.img`
  margin-top: 40px;
  width: ${COURIER_IMAGE_SIZE}px;
  height: ${COURIER_IMAGE_SIZE}px;
`

const LOCAL_STORAGE_KEY = 'relay-customer-portal-tiktok-safe-place-modal'

/**
 * Prompt user to add safe place if not already.
 * This is created mainly for tiktok
 */
function SafePlaceModal() {
  const {
    orderData: {
      trackingNumber,
      safePlacePreference,
      safePlacesList: safePlacesListFromBE,
      brandName,
    },
  } = useOrderPageContext()
  const isTiktok = useIsTiktokSafePlace()

  const {
    loading,
    error,
    onSaveSafePlace,
    selectedPlace,
    setSelectedPlace,
    neighbourDoorNumber,
    setNeighbourDoorNumber,
  } = useSafePlace()

  const [showModal, setShowModal] = useState<boolean>(false)

  const isWithoutSafePlace = !safePlacePreference
  const localStorageKey = `${LOCAL_STORAGE_KEY}-${trackingNumber}`

  const safePlacesList =
    safePlacesListFromBE ?? getFallbackSafePlaceList(isTiktok)
  const isNeighbourSelected = selectedPlace.some((place) =>
    Boolean(place?.id === NEIGHBOUR_ID)
  )

  const handleChangeSavePlace = (safePlaceId: string) => {
    const safePlace = safePlacesList.find(
      (place: SafePlaceType) => place.id === safePlaceId
    )
    setSelectedPlace(safePlace ? [safePlace] : [])
    if (safePlace?.id !== NEIGHBOUR_ID) {
      setNeighbourDoorNumber('')
    }
  }

  const handleSaveSafePlace = async () => {
    if (gtag) {
      gtag('event', 'safe_place_modal_click', {
        screen_name: 'safePlaceModal',
        button_name: 'add_safe_place',
      })
    }

    if (selectedPlace) {
      await onSaveSafePlace()
    }
  }

  useEffect(() => {
    const hasSeenModal = localStorage.getItem(localStorageKey) === '1'

    if (isWithoutSafePlace && !hasSeenModal) {
      setShowModal(true)
      localStorage.setItem(localStorageKey, '1')
    }
  }, [isWithoutSafePlace, localStorageKey])

  // must be tik tok, and doesn't have safe place saved
  if (!isWithoutSafePlace) {
    return null
  }

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      size={['full', 'full', 'auto']}
      margin={'auto'}
    >
      <Modal.Content
        width={'100%'}
        height={['100%', '100%', 'auto']}
        maxHeight={['full', 'full', '700px']}
        margin={0}
        paddingTop={0}
        paddingX={['16px', '16px', '32px']}
      >
        <ModalRelayLogo />
        <Modal.CloseButton />
        <Modal.Body>
          <VStack space={8} alignItems={'center'}>
            <StyledCourierImg src={courierImg} />
            <VStack space={2} alignItems={'center'}>
              <Text
                textAlign={'center'}
                fontWeight={'semibold'}
                fontSize={'xl'}
              >
                {isTiktok
                  ? 'Set your safe places'
                  : `Help us deliver your ${brandName} parcel successfully`}
              </Text>
              <Text textAlign={'center'}>
                {isTiktok
                  ? 'Let your courier know all the places you’re happy for them to deliver the parcel'
                  : 'If you will not be home please add a safe place'}
              </Text>
            </VStack>

            <VStack width={'100%'}>
              {isTiktok ? (
                <SafePlaceMultiCheckbox
                  selectedPlace={selectedPlace}
                  safePlacesList={safePlacesList}
                  loading={loading}
                  neighbourDoorNumber={neighbourDoorNumber}
                  onChangeSelectedSafePlace={(ids: string[]) => {
                    setSelectedPlace(
                      ids
                        .map(
                          (id) =>
                            safePlacesList.find(
                              (place: SafePlaceType) => place.id === id
                            ) ?? null
                        )
                        .filter((place): place is SafePlaceType => !!place)
                    )
                    if (ids.includes(NEIGHBOUR_ID)) {
                      setNeighbourDoorNumber('')
                    }
                  }}
                  onChangeNeighbourDoorNumber={(text: string) =>
                    setNeighbourDoorNumber(
                      text.slice(0, neighbourMaxCharacterLimit)
                    )
                  }
                />
              ) : (
                <SafePlaceListRadioGroup
                  selectedPlace={selectedPlace}
                  safePlacesList={safePlacesList}
                  loading={loading}
                  neighbourDoorNumber={neighbourDoorNumber}
                  onChangeSelectedSafePlace={(id: string) =>
                    handleChangeSavePlace(id)
                  }
                  onChangeNeighbourDoorNumber={(text: string) =>
                    setNeighbourDoorNumber(
                      text.slice(0, neighbourMaxCharacterLimit)
                    )
                  }
                />
              )}

              {isTiktok && (
                <Box backgroundColor={'relay.gray'} p={3} my={2}>
                  <Text>
                    By setting safe places, you take responsibility for the loss
                    or damage of any item delivered here
                  </Text>
                </Box>
              )}

              <VStack space={3} my={4}>
                <Button
                  borderRadius={'full'}
                  _text={{
                    fontWeight: 'extraBold',
                  }}
                  alignSelf={['stretch', 'stretch']}
                  px={6}
                  onPress={handleSaveSafePlace}
                  isDisabled={
                    !selectedPlace ||
                    (isNeighbourSelected && !neighbourDoorNumber)
                  }
                  isLoading={loading}
                >
                  {isTiktok ? 'Set safe places' : 'Add safe place'}
                </Button>

                <Button
                  borderRadius={'full'}
                  _text={{
                    fontWeight: 'extraBold',
                    color: 'relay.textPrimary',
                  }}
                  alignSelf={['stretch', 'stretch']}
                  px={6}
                  bgColor={'relay.gray'}
                  onPress={() => {
                    setShowModal(false)

                    if (gtag) {
                      gtag('event', 'safe_place_modal_click', {
                        screen_name: 'safePlaceModal',
                        button_name: 'i_am_home',
                      })
                    }
                  }}
                  isDisabled={loading}
                >
                  I’ll be home
                </Button>
              </VStack>

              {!!error && <Text color={'danger.500'}>{error}</Text>}
            </VStack>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}

export default SafePlaceModal
