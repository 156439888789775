import { OrderData, SafePlaceType } from '../pages/Order/types'
import { apiRoutes } from './apiRoutes'
import fetchApi from './fetchApi'

type ResponseModel = OrderData

async function updatePreferredSafePlace(
  trackingNumber: string,
  action: 'set' | 'clear',
  data?: {
    safePlaceType?: SafePlaceType
    neighbourDoorNumber: string | null
    safePlaces?: SafePlaceType[]
  }
): Promise<ResponseModel> {
  const { safePlaceType, neighbourDoorNumber, safePlaces } = data ?? {}

  const response = await fetchApi.post<ResponseModel>(
    apiRoutes.updatePreferredSafePlace(trackingNumber),
    {
      action,
      safe_place_type: safePlaceType,
      neighbour_door_number: neighbourDoorNumber,
      safe_places: safePlaces,
    }
  )

  return response
}

export default updatePreferredSafePlace
