import { Box, Button, Input, Radio, Text, VStack } from 'native-base'
import React, { useCallback, useState } from 'react'

import { apiRoutes } from '../../api/apiRoutes'
import { RelayError } from '../../api/fetchApi'
import { PADDING_WITH_BREAKPOINTS } from '../../core/Layout'
import LoadingSpinner from '../../core/LoadingSpinner'
import wordmark from '../../images/wordmark.svg'
import { downloadLabel } from './downloadLabel'
import { shipperPost } from './shipperPost'
import {
  CreateShipmentResponse,
  UserDetails,
  ValidationFailureDetails,
} from './types'
import UploadShipmentCsv from './UploadShipmentCsv'
import { useShipperGet } from './useShipperGet'
import { withUnauthorizedRedirect } from './withUnauthorizedRedirect'

function CreateShipment() {
  const [name, nameEl, setNameError, resetName] = useInputItem(
    'Customer name',
    true
  )
  const [
    addressLine1,
    addressLine1El,
    setAddressLine1Error,
    resetAddressLine1,
  ] = useInputItem('Address Line 1', true)
  const [
    addressLine2,
    addressLine2El,
    setAddressLine2Error,
    resetAddressLine2,
  ] = useInputItem('Address Line 2', false)
  const [town, townEl, setTownError, resetTown] = useInputItem('Town', false)
  const [postcode, postcodeEl, setPostcodeError, resetPostcode] = useInputItem(
    'Postcode',
    true
  )
  const [phone, phoneEl, setPhoneError, resetPhone] = useInputItem(
    'Customer phone number',
    false
  )
  const [email, emailEl, setEmailError, resetEmail] = useInputItem(
    'Customer email address',
    false
  )
  const [service, setService] = useState<string>()
  const [serviceError, setServiceError] = useState<string>()
  const [error, setError] = useState<string>()
  const [message, setMessage] = useState<string>()
  const [submissionInProgress, setSubmissionInProgress] = useState(false)

  const {
    data: userDetails,
    loading,
    error: fetchError,
  } = useShipperGet<UserDetails>(apiRoutes.shipperPortal.getUserDetails())

  const createShipment = useCallback(async () => {
    ;[
      setNameError,
      setAddressLine1Error,
      setAddressLine2Error,
      setTownError,
      setPostcodeError,
      setPhoneError,
      setEmailError,
      setServiceError,
    ].forEach((errorFn) => errorFn(undefined))
    setMessage(undefined)
    let valid = true
    if (name.trim() === '') {
      setNameError('Name must not be empty')
      valid = false
    }
    if (addressLine1.trim() === '') {
      setAddressLine1Error('Address line 1 must not be empty')
      valid = false
    }
    if (postcode.trim() === '') {
      setPostcodeError('Postcode must not be empty')
      valid = false
    }
    if (!service) {
      setServiceError('You must choose a service level')
      valid = false
    }
    if (!valid) {
      return
    }
    setSubmissionInProgress(true)
    try {
      const response = await withUnauthorizedRedirect(() =>
        shipperPost<CreateShipmentResponse>(
          apiRoutes.shipperPortal.createShipment(),
          {
            service_type: service ?? null,
            parcel: { contents: [] },
            origin: { location_type: 'retailer_location' },
            destination: {
              location_type: 'customer',
              customer: {
                name,
                mobile: phone,
                email,
                address: {
                  line1: addressLine1,
                  line2: addressLine2,
                  town,
                  postcode,
                },
              },
            },
          }
        )
      )
      setMessage(`Created shipment ${response.barcode}`)
      downloadLabel(response.barcode)
      ;[
        resetName,
        resetAddressLine1,
        resetAddressLine2,
        resetTown,
        resetPostcode,
        resetPhone,
        resetEmail,
      ].forEach((resetFn) => resetFn())
      setService(undefined)
    } catch (e: unknown) {
      if (!(e instanceof RelayError)) {
        throw e
      }
      if (e.body && 'detail' in e.body) {
        if (typeof e.body.detail === 'string') {
          setError(e.body.detail)
        } else if (typeof e.body.detail === 'object') {
          const detail = e.body.detail as ValidationFailureDetails
          const mapping = {
            'body,destination,CustomerLocation,customer,name': setNameError,
            'body,destination,CustomerLocation,customer,mobile': setPhoneError,
            'body,destination,CustomerLocation,customer,email': setEmailError,
            'body,destination,CustomerLocation,customer,address,line1':
              setAddressLine1Error,
            'body,destination,CustomerLocation,customer,address,line2':
              setAddressLine2Error,
            'body,destination,CustomerLocation,customer,address,town':
              setTownError,
            'body,destination,CustomerLocation,customer,address,postcode':
              setPostcodeError,
            'body,service_type': setServiceError,
          } as Record<string, ((e: string) => void) | undefined>
          // If we can't find any error associated with a field, we should show
          // them something so they know the request failed
          let someErrorShown = false
          for (const issue of detail) {
            const loc = issue.loc.join(',')
            const errorFn = mapping[loc]
            if (errorFn) {
              errorFn(issue.msg)
              someErrorShown = true
            }
          }
          if (!someErrorShown) {
            setError('Unknown error creating label')
          }
        }
      }
    } finally {
      setSubmissionInProgress(false)
    }
  }, [
    setNameError,
    setAddressLine1Error,
    setAddressLine2Error,
    setTownError,
    setPostcodeError,
    setPhoneError,
    setEmailError,
    service,
    name,
    phone,
    email,
    addressLine1,
    addressLine2,
    town,
    postcode,
    resetName,
    resetAddressLine1,
    resetAddressLine2,
    resetTown,
    resetPostcode,
    resetPhone,
    resetEmail,
  ])

  if (loading || fetchError) {
    return (
      <LoadingSpinner
        testId={'AppLoadingSpinner'}
        accessibilityLabel={'Loading the App'}
      />
    )
  }

  return (
    <VStack
      maxWidth={'90%'}
      width={'600px'}
      alignSelf={'center'}
      justifyContent={'stretch'}
      padding={PADDING_WITH_BREAKPOINTS}
    >
      <Box>
        <img src={wordmark} alt={'Relay wordmark'} width={'150px'} />
      </Box>
      <Text fontSize={'3xl'} bold mt={6}>
        Label creation
      </Text>
      {userDetails && (
        <Text fontSize={'md'} mt={1} color={'relay.textSecondary'}>
          {userDetails.shipperName} — {userDetails.emailAddress}
        </Text>
      )}
      <Box mt={6}>
        <UploadShipmentCsv />
      </Box>
      {nameEl}
      {addressLine1El}
      {addressLine2El}
      {townEl}
      {postcodeEl}
      {phoneEl}
      {emailEl}
      <Radio.Group
        name={'service'}
        value={service ?? ''}
        onChange={setService}
        mt={6}
      >
        <Radio value={'same_day'}>Same Day</Radio>
        <Radio value={'next_day'}>Next Day</Radio>
      </Radio.Group>
      {serviceError && <Text color={'relay.error'}>{serviceError}</Text>}
      <Button onPress={createShipment} mt={6} disabled={submissionInProgress}>
        Create Shipment
      </Button>
      <Box display={submissionInProgress ? 'block' : 'none'} mt={6}>
        <LoadingSpinner
          testId={'AppLoadingSpinner'}
          accessibilityLabel={'Loading the App'}
          height={'auto'}
        />
      </Box>
      <Box mt={6}>
        {error && <Text color={'relay.error'}>{error}</Text>}
        {message && <Text color={'relay.volt'}>{message}</Text>}
      </Box>
    </VStack>
  )
}

function useInputItem(
  title: string,
  required: boolean
): [string, JSX.Element, (err: string | undefined) => void, () => void] {
  const [value, setValue] = useState('')
  const [error, setError] = useState<string>()

  const resetValue = useCallback(() => {
    setValue('')
  }, [])

  const el = (
    <>
      <Text color={'relay.volt'} mt={6}>
        {title}
        {required && '*'}
      </Text>
      <Input
        value={value}
        type={'text'}
        variant={'filled'}
        w={'100%'}
        size={'lg'}
        isRequired={required}
        onChangeText={setValue}
        _hover={{
          borderColor: 'relay.volt',
        }}
        _focus={{
          borderColor: 'relay.volt',
          _hover: { borderColor: 'relay.volt' },
        }}
      />
      {error && <Text color={'relay.error'}>{error}</Text>}
    </>
  )

  return [value, el, setError, resetValue]
}

export default CreateShipment
