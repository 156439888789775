import { useToast } from 'native-base'
import { useState } from 'react'

import updatePreferredSafePlace from '../../../../api/updatePreferredSafePlace'
import { useOrderPageContext } from '../../OrderPageContext'
import { SafePlace as SafePlaceType, SafePlacePreference } from '../../types'
import { useIsTiktokSafePlace } from '../shared/useIsTiktokSafePlace'
import { getFallbackSafePlaceList, getSafePlace } from './safePlaceList'

const TOAST_ID = 'save-safe-place-toast'
const SUCCESS_TOAST_TEXT = 'Safe Place Saved!'

const useSaveSafePlace = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const {
    orderData: { trackingNumber, safePlacePreference },
    updateOrderData,
  } = useOrderPageContext()
  const toast = useToast()
  const isTiktok = useIsTiktokSafePlace()

  const enqueueToast = () => {
    // check isActive to avoid duplicate toast
    if (!toast.isActive(TOAST_ID)) {
      toast.show({
        id: TOAST_ID,
        placement: 'bottom',
        description: SUCCESS_TOAST_TEXT,
        accessibilityAnnouncement: SUCCESS_TOAST_TEXT,
      })
    }
  }

  const saveSafePlace = async (
    selectedPlace: SafePlaceType[],
    neighbourDoorNumber: string | null
  ) => {
    setLoading(true)

    const safePlaces = selectedPlace
      .filter((place): place is SafePlaceType => Boolean(place))
      .map((place) => place?.id)

    try {
      const { safePlacePreference } = await updatePreferredSafePlace(
        trackingNumber,
        'set',
        {
          safePlaceType: isTiktok ? undefined : selectedPlace[0]?.id,
          neighbourDoorNumber,
          safePlaces: isTiktok ? safePlaces : undefined,
        }
      )
      if (safePlacePreference) {
        updateOrderData('safePlacePreference', safePlacePreference)
      } else {
        throw new Error('Cannot find safe place. Please try again.')
      }

      enqueueToast()
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((e as any)?.message)
    }

    setLoading(false)
  }

  const clearSafePlace = async () => {
    // if currently there's no safe place selected, don't need to attempt
    // to clear it out
    if (!safePlacePreference?.safePlaceType) {
      return
    }

    setLoading(true)

    try {
      const { safePlacePreference } = await updatePreferredSafePlace(
        trackingNumber,
        'clear'
      )
      updateOrderData('safePlacePreference', safePlacePreference)
      enqueueToast()
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((e as any)?.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    saveSafePlace,
    clearSafePlace,
  }
}

export function getSelectedSafePlace(
  safePlacePreference: SafePlacePreference,
  isTiktok: boolean
): SafePlaceType[] {
  if (safePlacePreference.safePlaces) {
    return safePlacePreference.safePlaces
      .map((place) => {
        const list = getFallbackSafePlaceList(isTiktok)
        return list.find((safePlace) => safePlace.id === place) ?? null
      })
      .filter((place): place is SafePlaceType => !!place)
  }

  const safePlace = getSafePlace(safePlacePreference, isTiktok)

  return safePlace ? [safePlace] : []
}

const useSafePlace = () => {
  const {
    orderData: { safePlacePreference },
  } = useOrderPageContext()
  const isTiktok = useIsTiktokSafePlace()

  const { loading, error, saveSafePlace, clearSafePlace } = useSaveSafePlace()
  const [selectedPlace, setSelectedPlace] = useState<SafePlaceType[]>(
    safePlacePreference
      ? getSelectedSafePlace(safePlacePreference, isTiktok)
      : []
  )
  const [neighbourDoorNumber, setNeighbourDoorNumber] = useState<string>(
    safePlacePreference?.neighbourDoorNumber ?? ''
  )

  const onSaveSafePlace = async () => {
    if (selectedPlace.length > 0) {
      await saveSafePlace(selectedPlace, neighbourDoorNumber || null)
    }
  }

  return {
    loading,
    error,
    onSaveSafePlace,
    selectedPlace,
    setSelectedPlace,
    neighbourDoorNumber,
    setNeighbourDoorNumber,
    clearSafePlace,
  }
}

export default useSafePlace
