import { Badge, Box, HStack, Text, VStack } from 'native-base'
import React from 'react'
import styled from 'styled-components'

import { PitstopLocation, PitstopLocationService } from '../types'
import { formateTime, getAddressText } from '../utils'

const GOOGLE_MAPS_DEFAULT_MARKER_ICON =
  'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless3_hdpi.png'

const MarkerIcon = styled.img`
  width: 100%;
`
const MarkerIconText = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
`

const getServiceText = (service: PitstopLocationService) => {
  switch (service) {
    case PitstopLocationService.DropOff:
      return 'Drop Off'
    case PitstopLocationService.PickUp:
      return 'Pick Up'
  }
}

type LocationCardProps = {
  data: PitstopLocation
  order: number
}

function LocationCard({ data, order }: LocationCardProps) {
  const { address, searchDistanceKm, supportedServices, openingHours } = data
  const addressText = getAddressText(address)

  return (
    <VStack
      bgColor={'relay.gray'}
      my={2}
      py={4}
      px={5}
      space={3}
      borderRadius={8}
      shadow={4}
    >
      <HStack space={2} alignItems={'center'} justifyContent={'space-between'}>
        <HStack space={4} flex={'1'} alignItems={'center'}>
          <Box w={'24px'}>
            <MarkerIcon
              src={GOOGLE_MAPS_DEFAULT_MARKER_ICON}
              alt={`The number ${order} return location`}
            />
            <MarkerIconText>
              <Text fontSize={'sm'}>{order}</Text>
            </MarkerIconText>
          </Box>
          <Text
            fontSize={'lg'}
            fontWeight={'semibold'}
          >{`${searchDistanceKm} km`}</Text>
        </HStack>
        {supportedServices.length > 0 && (
          <HStack>
            {supportedServices.map((service) => (
              <Badge key={service} bgColor={'relay.moss'} marginLeft={1}>
                {getServiceText(service)}
              </Badge>
            ))}
          </HStack>
        )}
      </HStack>

      <VStack>
        {address.organisation && (
          <Text fontWeight={'semibold'}>{address.organisation}</Text>
        )}
        {addressText && <Text opacity={0.9}>{addressText}</Text>}
      </VStack>

      {openingHours.length > 0 && (
        <VStack>
          <Text fontWeight={'semibold'}>Opening Hours</Text>
          {openingHours.map(({ dow, opens, closes }) => (
            <HStack key={dow}>
              <Text textTransform={'capitalize'}>{dow}</Text>
              <Text>{`: ${formateTime(opens)} - ${formateTime(closes)}`}</Text>
            </HStack>
          ))}
        </VStack>
      )}
    </VStack>
  )
}

export default LocationCard
