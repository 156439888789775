import { useBackendFeatureFlagsContext } from '../../../../core/FeatureFlag'
import { useOrderPageContext } from '../../OrderPageContext'

export function useIsTiktokSafePlace(): boolean {
  const {
    orderData: { brandName },
  } = useOrderPageContext()
  const { customerPortalTiktokSafePlaceUpdate } =
    useBackendFeatureFlagsContext()

  return (
    customerPortalTiktokSafePlaceUpdate && brandName?.toLowerCase() === 'tiktok'
  )
}
