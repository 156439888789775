import { PitstopLocationAddress } from './types'

export const getAddressText = (address: PitstopLocationAddress): string => {
  const { line1, line2, line3, line4, town, postcode } = address

  let text = ''

  if (line1) {
    text += line1
  }

  if (line2) {
    text += `, ${line2}`
  }

  if (line3) {
    text += `, ${line3}`
  }

  if (line4) {
    text += `, ${line4}`
  }

  if (town) {
    text += `\n${town}`
  }

  if (postcode) {
    text += `\n${postcode}`
  }

  return text
}

// Assume timeString comes in `0800` format
export const formateTime = (timeString: string): string => {
  // don't attemp to do anything if assumption is not right
  if (timeString.length !== 4) {
    return timeString
  }

  return `${timeString.substring(0, 2)}:${timeString.substring(2)}`
}
