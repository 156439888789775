import { Box, Center, Link, Stack, VStack } from 'native-base'
import React, { PropsWithChildren } from 'react'

export const LAYOUT_HEIGHT_WITH_BREAKPOINTS = ['auto', 'auto', '100vh']
export const PADDING_WITH_BREAKPOINTS = [0, 0, 60]
export const SPACE_WITH_BREAKPOINTS = [7, 7, 5]

type Props = {
  renderMap?: () => JSX.Element
  showFooter?: boolean
}

/** The common layout for most pages */
function Layout({
  renderMap,
  showFooter = true,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Stack
      direction={['column', 'column', 'row']}
      bg={'black'}
      height={LAYOUT_HEIGHT_WITH_BREAKPOINTS}
      padding={PADDING_WITH_BREAKPOINTS}
      space={SPACE_WITH_BREAKPOINTS}
    >
      {renderMap?.()}
      <Box flex={'1'} mx={[6, 6, 0]} overflowY={'auto'}>
        <VStack space={5}>
          {children}
          {showFooter && (
            <Center>
              <Link
                testID={'ContactLink'}
                isUnderlined={false}
                _text={{
                  fontWeight: 'bold',
                }}
                href={'https://relaytech-support.elevio.help/en'}
                marginBottom={5}
              >
                Need help? Contact us
              </Link>
            </Center>
          )}
        </VStack>
      </Box>
    </Stack>
  )
}

export default Layout
