import dayjs from 'dayjs'

import { useOrderPageContext } from './OrderPageContext'
import { BadgeText, DeliveryStatusType } from './types'

const getTimeLeftToPickUp = (
  actionDeadlineAt?: string | null
): number | null => {
  if (!actionDeadlineAt) {
    return null
  }

  const now = dayjs()

  const deadline = dayjs(actionDeadlineAt)
  const hoursTillDeadline = deadline.diff(now, 'h')

  return now.isBefore(deadline) ? Math.max(hoursTillDeadline, 1) : null
}

function useBadgeText(): BadgeText | undefined {
  const {
    orderData: { statusBadgeText, actionDeadlineAt, deliveryStatus },
  } = useOrderPageContext()
  const timeLeftToPickUp = getTimeLeftToPickUp(actionDeadlineAt)

  if (statusBadgeText) {
    return {
      label: statusBadgeText,
      type: 'info',
    }
  }

  if (
    timeLeftToPickUp !== null &&
    deliveryStatus === DeliveryStatusType.AwaitingCustomerCollection &&
    timeLeftToPickUp <= 24
  ) {
    const hoursText = timeLeftToPickUp === 1 ? 'hour' : 'hours'
    return {
      label: `Only ${timeLeftToPickUp} ${hoursText} left to pick up`,
      type: 'warn',
    }
  }

  return undefined
}

export default useBadgeText
