export type PitstopLocation = {
  code: string
  address: PitstopLocationAddress
  supportedServices: PitstopLocationService[]
  openingHours: OpeningHour[]
  searchDistanceKm: number
}

export type PitstopLocationAddress = {
  organisation?: string | null
  line1: string
  line2?: string | null
  line3?: string | null
  line4?: string | null
  town?: string | null
  postcode: string
  countryIso: string
  lat: number
  lon: number
}

export enum PitstopLocationService {
  DropOff = 'drop_off',
  PickUp = 'pick_up',
}

export type OpeningHour = {
  dow: string
  opens: string
  closes: string
}
