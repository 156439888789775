import {
  Box,
  Button,
  ChevronLeftIcon,
  Heading,
  Hidden,
  Stack,
  VStack,
} from 'native-base'
import React, { useState } from 'react'

import Map from './Map'
import PitstopLocationsList from './PitstopLocationsList'
import SearchInput from './SearchInput'
import useGetC2CLocations from './useGetC2CLocations'
import useGoBackButton from './useGoBackButton'

// Set in london for now. This is only used on page load.
const DEFAULT_MAP_CENTER = {
  lat: 51.5007325,
  lon: -0.1272003,
}

/** Page for rendering return locations near a zip code */
function C2CLocations() {
  const { loading, error, data, searchQueryParam, updateSearchQueryParam } =
    useGetC2CLocations()

  const [searchInput, setSearchInput] = useState<string>(searchQueryParam ?? '')
  const handleSearch = () => {
    updateSearchQueryParam(searchInput)
  }

  const renderSearchInput = () => (
    <SearchInput
      value={searchInput}
      onChangeText={(text) => setSearchInput(text)}
      onPressSearch={handleSearch}
    />
  )

  const goBackButton = useGoBackButton()

  return (
    <Stack
      direction={['column', 'column', 'row']}
      width={'100%'}
      height={'100vh'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      bgColor={'relay.black'}
    >
      <VStack
        h={'100%'}
        w={['100%', '100%', '40%', '550px']}
        p={[4, 4, 6, 10]}
        pt={4}
        overflow={'auto'}
      >
        {!!goBackButton?.text && (
          <Box alignItems={'flex-start'}>
            <Button
              bgColor={'transparent'}
              _text={{
                color: 'relay.volt',
              }}
              leftIcon={<ChevronLeftIcon />}
              pl={0}
              _icon={{
                color: 'relay.black',
                bgColor: 'relay.volt',
                p: '2px',
                borderRadius: '4px',
              }}
              onPress={goBackButton.goBack}
            >
              {goBackButton.text}
            </Button>
          </Box>
        )}
        <Heading my={4} fontWeight={'normal'}>
          Find your nearest Relay Pitstop
        </Heading>
        <Hidden from={'md'}>{renderSearchInput()}</Hidden>
        <Hidden from={'md'}>
          <>
            <Box mb={6}>
              <Map mapCenter={DEFAULT_MAP_CENTER} locations={data} />
            </Box>
            {searchQueryParam && (
              <PitstopLocationsList
                loading={loading}
                error={error}
                locations={data}
              />
            )}
          </>
        </Hidden>
        <Hidden till={'md'}>
          <VStack flex={'1'}>
            {renderSearchInput()}
            {searchQueryParam && (
              <PitstopLocationsList
                loading={loading}
                error={error}
                locations={data}
              />
            )}
          </VStack>
        </Hidden>
      </VStack>
      <Hidden till={'md'}>
        <Box flex={'1'} h={'100%'} overflow={'hidden'}>
          <Map mapCenter={DEFAULT_MAP_CENTER} locations={data} />
        </Box>
      </Hidden>
    </Stack>
  )
}

export default C2CLocations
