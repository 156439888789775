import { PitstopLocation } from '../../types'

export const getBounds = (
  locations: PitstopLocation[]
): { lat: number; lon: number }[] => {
  return locations.map(({ address }) => ({
    lat: address.lat,
    lon: address.lon,
  }))
}
