import { PitstopLocation, PitstopLocationService } from '../../types'

export const mockPitstopLocations: PitstopLocation[] = [
  {
    code: 'PTLWM11F',
    address: {
      organisation: 'S.B.C London',
      line1: '130 Essex Road',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'N1 8LX',
      countryIso: 'GBR',
      lat: 51.539307,
      lon: -0.09783,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'tue',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'wed',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'thu',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'fri',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'sat',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'sun',
        opens: '0800',
        closes: '2000',
      },
    ],
    searchDistanceKm: 0.27,
  },
  {
    code: 'PTG24YF8',
    address: {
      organisation: 'G&B Convenience Store',
      line1: '46 Roman Way',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'N7 8XF',
      countryIso: 'GBR',
      lat: 51.546806,
      lon: -0.115623,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'tue',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'wed',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'thu',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'fri',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sat',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sun',
        opens: '0900',
        closes: '2200',
      },
    ],
    searchDistanceKm: 1.62,
  },
  {
    code: 'PTLJNNVQ',
    address: {
      organisation: 'Nisa Local Great Eastern',
      line1: '8 Rivington St',
      line2: 'London EC2A 3DU',
      line3: null,
      line4: null,
      town: null,
      postcode: 'EC2A 3JL',
      countryIso: 'GBR',
      lat: 51.525749,
      lon: -0.082513,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'tue',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'wed',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'thu',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'fri',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sat',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sun',
        opens: '0900',
        closes: '2200',
      },
    ],
    searchDistanceKm: 1.84,
  },
  {
    code: 'PT7MM2R5',
    address: {
      organisation: 'Mereb food and wine',
      line1: '415 Holloway Road',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'N7 6HJ',
      countryIso: 'GBR',
      lat: 51.558025,
      lon: -0.120758,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'tue',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'wed',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'thu',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'fri',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sat',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sun',
        opens: '0900',
        closes: '2200',
      },
    ],
    searchDistanceKm: 2.66,
  },
  {
    code: 'PT5NFYHH',
    address: {
      organisation: 'Camden Mini Market',
      line1: '31 Pratt Street',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'NW1 0BG',
      countryIso: 'GBR',
      lat: 51.537827,
      lon: -0.139556,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0730',
        closes: '2300',
      },
      {
        dow: 'tue',
        opens: '0730',
        closes: '2300',
      },
      {
        dow: 'wed',
        opens: '0730',
        closes: '2300',
      },
      {
        dow: 'thu',
        opens: '0730',
        closes: '2300',
      },
      {
        dow: 'fri',
        opens: '0730',
        closes: '2300',
      },
      {
        dow: 'sat',
        opens: '0730',
        closes: '2300',
      },
      {
        dow: 'sun',
        opens: '0730',
        closes: '2300',
      },
    ],
    searchDistanceKm: 3.13,
  },
  {
    code: 'PT2GJK63',
    address: {
      organisation: 'Thondon Supermarket',
      line1: '270 Green Lanes',
      line2: 'Woodberry Down',
      line3: null,
      line4: null,
      town: null,
      postcode: 'N4 2HE',
      countryIso: 'GBR',
      lat: 51.56979,
      lon: -0.09464,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0900',
        closes: '0900',
      },
      {
        dow: 'tue',
        opens: '0900',
        closes: '0900',
      },
      {
        dow: 'wed',
        opens: '0900',
        closes: '0900',
      },
      {
        dow: 'thu',
        opens: '0900',
        closes: '0900',
      },
      {
        dow: 'fri',
        opens: '0900',
        closes: '0900',
      },
      {
        dow: 'sat',
        opens: '0900',
        closes: '0900',
      },
      {
        dow: 'sun',
        opens: '0900',
        closes: '0900',
      },
    ],
    searchDistanceKm: 3.25,
  },
  {
    code: 'PT2PCWFR',
    address: {
      organisation: 'Supreme Mobilez',
      line1: '7 Terrace Road',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'E9 7ES',
      countryIso: 'GBR',
      lat: 51.543472,
      lon: -0.046959,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'tue',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'wed',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'thu',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'fri',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sat',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sun',
        opens: '0900',
        closes: '2200',
      },
    ],
    searchDistanceKm: 3.3,
  },
  {
    code: 'PT7GFCXY',
    address: {
      organisation: 'Londis',
      line1: '112-116 Lauriston Road',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'E9 7LH',
      countryIso: 'GBR',
      lat: 51.537689,
      lon: -0.045694,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0700',
        closes: '2100',
      },
      {
        dow: 'tue',
        opens: '0700',
        closes: '2100',
      },
      {
        dow: 'wed',
        opens: '0700',
        closes: '2100',
      },
      {
        dow: 'thu',
        opens: '0700',
        closes: '2100',
      },
      {
        dow: 'fri',
        opens: '0700',
        closes: '2100',
      },
      {
        dow: 'sat',
        opens: '0700',
        closes: '2100',
      },
      {
        dow: 'sun',
        opens: '0700',
        closes: '2100',
      },
    ],
    searchDistanceKm: 3.39,
  },
  {
    code: 'PT7RGTHY',
    address: {
      organisation: 'Tfx International Food Centre',
      line1: '124-128 Roman Rd',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'E2 0RN',
      countryIso: 'GBR',
      lat: 51.528866,
      lon: -0.04708,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'tue',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'wed',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'thu',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'fri',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'sat',
        opens: '0800',
        closes: '2000',
      },
      {
        dow: 'sun',
        opens: '0800',
        closes: '2000',
      },
    ],
    searchDistanceKm: 3.52,
  },
  {
    code: 'PTG6PJLQ',
    address: {
      organisation: 'Let’s goo express',
      line1: '2 Chester court',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'NW1 4BU',
      countryIso: 'GBR',
      lat: 51.528721,
      lon: -0.144516,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'tue',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'wed',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'thu',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'fri',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sat',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sun',
        opens: '0900',
        closes: '2200',
      },
    ],
    searchDistanceKm: 3.7,
  },
  {
    code: 'PTG9CXNP',
    address: {
      organisation: 'News Con',
      line1: '90 Chatsworth Road',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'E5 0LS',
      countryIso: 'GBR',
      lat: 51.554021,
      lon: -0.04482,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'tue',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'wed',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'thu',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'fri',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sat',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sun',
        opens: '0900',
        closes: '2200',
      },
    ],
    searchDistanceKm: 3.74,
  },
  {
    code: 'PT0VL3J7',
    address: {
      organisation: 'Ashfield Convenience Store',
      line1: '89 Ashfield St',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'E1 2HA',
      countryIso: 'GBR',
      lat: 51.515709,
      lon: -0.057052,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0800',
        closes: '2300',
      },
      {
        dow: 'tue',
        opens: '0800',
        closes: '2300',
      },
      {
        dow: 'wed',
        opens: '0800',
        closes: '2300',
      },
      {
        dow: 'thu',
        opens: '0800',
        closes: '2300',
      },
      {
        dow: 'fri',
        opens: '0800',
        closes: '2300',
      },
      {
        dow: 'sat',
        opens: '0800',
        closes: '2300',
      },
      {
        dow: 'sun',
        opens: '0800',
        closes: '2300',
      },
    ],
    searchDistanceKm: 3.79,
  },
  {
    code: 'PTKHP57L',
    address: {
      organisation: 'Butlers News',
      line1: '73 Junction Road',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'N19 5QU',
      countryIso: 'GBR',
      lat: 51.563538,
      lon: -0.135423,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0600',
        closes: '1900',
      },
      {
        dow: 'tue',
        opens: '0600',
        closes: '1900',
      },
      {
        dow: 'wed',
        opens: '0600',
        closes: '1900',
      },
      {
        dow: 'thu',
        opens: '0600',
        closes: '1900',
      },
      {
        dow: 'fri',
        opens: '0600',
        closes: '1900',
      },
      {
        dow: 'sat',
        opens: '0600',
        closes: '1900',
      },
      {
        dow: 'sun',
        opens: '0600',
        closes: '1900',
      },
    ],
    searchDistanceKm: 3.82,
  },
  {
    code: 'PTGP38YL',
    address: {
      organisation: 'Ozan food and wine',
      line1: '24 Hermitage Road',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'N4 1LY',
      countryIso: 'GBR',
      lat: 51.574978,
      lon: -0.095951,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'tue',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'wed',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'thu',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'fri',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sat',
        opens: '0900',
        closes: '2200',
      },
      {
        dow: 'sun',
        opens: '0900',
        closes: '2200',
      },
    ],
    searchDistanceKm: 3.83,
  },
  {
    code: 'PTK574VP',
    address: {
      organisation: 'Sunny Store',
      line1: '149 Highgate Road',
      line2: null,
      line3: null,
      line4: null,
      town: null,
      postcode: 'NW5 1LJ',
      countryIso: 'GBR',
      lat: 51.556389,
      lon: -0.14656,
    },
    supportedServices: ['drop_off'] as PitstopLocationService[],
    openingHours: [
      {
        dow: 'mon',
        opens: '0500',
        closes: '2100',
      },
      {
        dow: 'tue',
        opens: '0500',
        closes: '2100',
      },
      {
        dow: 'wed',
        opens: '0500',
        closes: '2100',
      },
      {
        dow: 'thu',
        opens: '0500',
        closes: '2100',
      },
      {
        dow: 'fri',
        opens: '0500',
        closes: '2100',
      },
      {
        dow: 'sat',
        opens: '0500',
        closes: '2100',
      },
      {
        dow: 'sun',
        opens: '0500',
        closes: '2100',
      },
    ],
    searchDistanceKm: 4.01,
  },
]
