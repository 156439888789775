import { PitstopLocation, PitstopLocationService } from '../pages/types'
import { apiRoutes } from './apiRoutes'
import fetchApi from './fetchApi'

export enum ApiC2CLocationService {
  CustomerDelivery = 'customer_delivery',
  CustomerReturn = 'customer_return',
  CustomerToCustomer = 'customer_to_customer',
}

type ApiC2CLocation = Omit<
  PitstopLocation,
  'searchDistanceKm' | 'supportedServices'
> & {
  distanceFromSearchPointM: number
  supportedServices: ApiC2CLocationService[]
}

function mapServices(
  services: ApiC2CLocationService[]
): PitstopLocationService[] {
  const servicesSet = new Set(
    services.flatMap((service) => {
      switch (service) {
        case ApiC2CLocationService.CustomerReturn:
          return [PitstopLocationService.DropOff]
        case ApiC2CLocationService.CustomerToCustomer:
          return [PitstopLocationService.DropOff, PitstopLocationService.PickUp]
        default:
          return []
      }
    })
  )

  return Array.from(servicesSet)
}

async function getC2CLocations(postcode: string): Promise<PitstopLocation[]> {
  const res = await fetchApi.get<ApiC2CLocation[]>(
    apiRoutes.getC2CLocations(postcode)
  )
  return res.map((location) => ({
    ...location,
    searchDistanceKm:
      Math.round((location.distanceFromSearchPointM / 1000) * 100) / 100, // Round to 2 decimal places
    supportedServices: mapServices(location.supportedServices),
  }))
}

export default getC2CLocations
